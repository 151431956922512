import HomeInfoContract from '@/views/HomeView.vue'

const name = 'fgts-lp-info-contract'
const component = HomeInfoContract

const complement = {
  name,
  component,
}

const subdomain = () => {
  const [subdomain] = window.location.host.split('.')
  return subdomain
}

const beforeEnter = (to, from, next) => {
  const redirectSubdomain = [
    'veiculo',
    'consig-privado',
    'auxilio',
    'imovel',
  ].includes(subdomain())

  if (redirectSubdomain) next({ path: '/contrate-agora' })
  else next()
}

export default [
  {
    path: '/',
    ...complement,
    beforeEnter,
  },
  {
    path: '/supersim',
    ...complement,
  },
  {
    path: '/contrate',
    ...complement,
  },
  {
    path: '/c-contrate',
    ...complement,
  },
  {
    path: '/e-contrate',
    ...complement,
  },
  {
    path: '/i-contrate',
    ...complement,
  },
]
