import Form from '@/views/FormView.vue'

const name = 'form-contract'
const component = Form

const complement = {
  name,
  component,
}

export default [
  {
    path: '/e-contrate-agora',
    ...complement,
  },
  {
    path: '/i-contrate-agora',
    ...complement,
  },
  {
    path: '/c-contrate-agora',
    ...complement,
  },
  {
    path: '/supersim-contrate-agora',
    ...complement,
  },
]
