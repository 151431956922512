import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import app from './modules/app'
import fgts from './modules/fgts'
import theme from './modules/theme'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  key: 'vuex',
  storage: window.sessionStorage,
  reducer: (state) => ({
    validation: state.validation,
  }),
})

export default new Vuex.Store({
  plugins: [vuexLocal.plugin],
  modules: {
    app: {
      namespaced: true,
      ...app,
    },
    fgts: {
      namespaced: true,
      ...fgts,
    },
    theme: {
      namespaced: true,
      ...theme,
    },
  },
})
