/* eslint-disable prefer-const */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-bitwise */

/**
 * Requires ./loadingViewMixin.js
 */

import { mapState, mapActions } from 'vuex'

import themeFallback from '@/lib/data/theme.json'

import Base from '@/lib/api/Base'

export default {
  data: () => ({
    themeKey: 'pd_admin_theme',
    themeVersionKey: 'pd_admin_theme_version',
    defaultDomain: 'pratadigital.com.br',
    domainsToForceChange: ['192', 'dev'],
    toExclude: ['business', 'edit'],
  }),
  computed: {
    ...mapState({
      theme: (state) => state.theme.theme,
    }),
    domain() {
      let domain = window.location.hostname

      if (this.isDomainToChange(domain)) return this.defaultDomain

      domain = window.location.hostname.split('.')
      domain.shift()
      domain = domain.join('.')

      return domain
    },
  },
  methods: {
    ...mapActions({
      setTheme: 'theme/SET_THEME',
    }),
    isDomainToChange(value) {
      let hasToChange = false
      let reg

      for (let i = 0; i < this.domainsToForceChange.length; i++) {
        const domain = this.domainsToForceChange[i]

        reg = new RegExp(`${domain}*`, 'g')
        hasToChange = reg.test(value)

        if (hasToChange) break
      }

      return hasToChange
    },
    hexToRGB(hex) {
      const rgb = []
      let mod
      let a
      let b

      for (let i = 0; i < 3; ++i) {
        mod = hex.length === 4 ? 1 : 2

        a = i * mod + 1
        b = mod - 1 + a

        rgb[i] = `0x${hex[a]}${hex[b]}`
      }

      return `${+rgb[0]},${+rgb[1]},${+rgb[2]}`
    },
    setCssVariable({ property, key, value }) {
      document.documentElement.style.setProperty(`--${property}-${key}`, value)
    },
    async registerCSSVariables() {
      for (const [property, values] of Object.entries(this.theme)) {
        if (!this.toExclude.includes(property)) {
          Object.entries(values).forEach(([key, value], index) => {
            // set colors and logo
            this.setCssVariable({ property, key, value })

            if (property === 'color') {
              // set RGB variation
              this.setCssVariable({
                property,
                key: `${key}-rgb`,
                value: this.hexToRGB(value),
              })

              // set loader colors
              this.setCssVariable({
                property,
                key: `animation${index + 1}`,
                value,
              })
            }
          })
        }
      }
    },
    async fetchTheme() {
      const api = new Base()
      const { domain } = this

      let theme = themeFallback

      try {
        await api.getTheme({ domain }).then(({ data }) => {
          theme = data.data
        })
      } catch {
        // ...
      }

      return theme
    },
  },
}
