<template>
  <div class="form-address">
    <div class="columns is-multiline">
      <div class="column is-full">
        <div class="form-contract__title">
          <h2><span>Endereço</span></h2>
        </div>
      </div>
      <div class="column">
        <div class="address">
          <base-input
            :blur="getAddress"
            :value="zipcode"
            :has-error="
              validation.hasError('zipcode') || zipcode_error ? true : false
            "
            :first-error="validation.firstError('zipcode') || zipcode_error"
            :is-passed="validation.isPassed('zipcode') && !zipcode_error"
            type="tel"
            format="#####-###"
            @input="zipcode = $event"
          >
            CEP <span class="address--span">*</span>
          </base-input>

          <base-input
            :value="street"
            :has-error="
              validation.hasError('street') || street_error ? true : false
            "
            :first-error="validation.firstError('street') || street_error"
            :is-passed="validation.isPassed('street') && !street_error"
            @input="street = $event"
          >
            Logradouro <span class="address--span">*</span>
          </base-input>
          <small style="font-size: 0.8rem">(Rua, avenida, etc)</small>

          <base-input
            ref="number"
            :value="number"
            :has-error="
              validation.hasError('number') || number_error ? true : false
            "
            :first-error="validation.firstError('number') || number_error"
            :is-passed="validation.isPassed('number') && !number_error"
            @input="number = $event"
          >
            Número <span class="address--span">*</span>
          </base-input>

          <base-input
            :value="unit"
            :has-error="
              validation.hasError('unit') || unit_error ? true : false
            "
            :first-error="validation.firstError('unit') || unit_error"
            :is-passed="validation.isPassed('unit') && !unit_error"
            @input="unit = $event"
          >
            Complemento
          </base-input>
        </div>
      </div>
      <div class="column">
        <div class="address">
          <base-input
            :value="neighborhood"
            :has-error="
              validation.hasError('neighborhood') || neighborhood_error
                ? true
                : false
            "
            :first-error="
              validation.firstError('neighborhood') || neighborhood_error
            "
            :is-passed="
              validation.isPassed('neighborhood') && !neighborhood_error
            "
            @input="neighborhood = $event"
          >
            Bairro <span class="address--span">*</span>
          </base-input>

          <p
            :class="{
              'has-error':
                validation.hasError('local') || local_error ? true : false,
              'has-success': validation.isPassed('local') && !local_error,
            }"
            class="control address__address-local address-number"
          >
            <select
              v-model="local"
              @focus="stateFocused = true"
              @blur="stateFocused = false"
              @change="city = ''"
            >
              <option :value="null">ESCOLHER</option>
              <option
                v-for="(state, index) in states"
                :key="index"
                :value="state.value"
                :required="true"
              >
                {{ state.name }}
              </option>
            </select>
            <label class="label">Estado <span>*</span></label>
            <i class="bar" />
            <span
              v-if="validation.hasError('local') || local_error ? true : false"
              class="help is-error"
            >
              {{ validation.firstError('local') || local_error }}
            </span>
          </p>

          <p
            :class="{
              'has-error':
                validation.hasError('city') || city_error ? true : false,
              'has-success': validation.isPassed('city') && !city_error,
            }"
            class="control address__address-local address-number"
          >
            <!-- city start -->
            <!-- <select v-model="city">
              <option :value="null">ESCOLHER</option>
              <option
                v-for="(item, index) in cities"
                :key="index"
                :value="item.value"
                :required="true"
              >
                {{ item.name }}
              </option>
            </select>
            <label class="label">Cidade <span>*</span></label>
            <i class="bar" />
            <span
              v-if="validation.hasError('city') || city_error ? true : false"
              class="help is-error"
            >
              {{ validation.firstError('city') || city_error }}
            </span> -->
            <!-- city end -->
            <base-select
              :value="city"
              :options="cities"
              autocomplete
              required
              has-add
              @selected="city = $event.value"
              @added="handleAdd($event)"
            >
              Cidade
            </base-select>
          </p>

          <!-- <base-input
            :value="city"
            :has-error="
              validation.hasError('city') || city_error ? true : false
            "
            :first-error="validation.firstError('city') || city_error"
            :is-passed="validation.isPassed('city') && !city_error"
            @input="city = $event"
          >
            Cidade <span class="address--span">*</span>
          </base-input> -->
        </div>
      </div>
    </div>
    <div class="columns">
      <!-- <div class="column is-6">
        <div class="address">
          <div class="form-contract__button-back">
            <button @click="backStep">VOLTAR</button>
          </div>
        </div>
      </div> -->

      <div class="column is-6">
        <div class="address">
          <div class="form-contract__button-next has-text-right">
            <button
              :disabled="!formIsValidated || actionLoading"
              type="submit"
              @click="submit()"
            >
              PRÓXIMO
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import SimpleVueValidator from 'simple-vue-validator'

import statesJSON from '@/lib/data/states.json'

//components
import BaseInput from '@/components/BaseInput.vue'
import BaseSelect from '@/components/BaseSelect.vue'

//lib
import FormContractApi from '@/lib/api/FGTS'

const Validator = SimpleVueValidator.Validator.create({
  templates: {
    required: 'Este campo não pode ficar vazio',
  },
})

export default {
  name: 'InfoAddress',

  components: {
    BaseInput,
    BaseSelect,
  },
  mixins: [SimpleVueValidator.mixin],

  validators: {
    zipcode(value) {
      this.zipcode_error = ''
      return Validator.value(value)
        .required()
        .minLength(9, 'Não foi possível validar seu CEP')
        .maxLength(9, 'Não foi possível validar seu CEP')
    },
    street(value) {
      this.street_error = ''
      return Validator.value(value).required()
    },
    number(value) {
      this.number_error = ''
      return Validator.value(value).required()
    },
    neighborhood(value) {
      this.neighborhood_error = ''
      return Validator.value(value).required()
    },
    city(value) {
      this.city_error = ''
      return Validator.value(value).required()
    },
    local(value) {
      this.local_error = ''
      return Validator.value(value).required()
    },
  },

  data: () => ({
    zipcode: '',
    zipcode_error: '',
    street: '',
    street_error: '',
    number: '',
    number_error: '',
    unit: '',
    unit_error: '',
    neighborhood: '',
    neighborhood_error: '',
    city: '',
    city_error: '',
    local: '',
    local_error: '',
    locations: {
      states: [],
      cities: [],
    },
    actionLoading: false,
    sourceAPI: axios.CancelToken.source(),
    stateFocused: false,
  }),

  computed: {
    ...mapState({
      account: (state) => state.fgts.account,
    }),

    formIsValidated() {
      return (
        this.validation.isPassed('zipcode') &&
        this.validation.isPassed('street') &&
        this.validation.isPassed('number') &&
        this.validation.isPassed('neighborhood') &&
        this.validation.isPassed('city')
      )
    },

    states() {
      return this.locations.states.map(({ nome, sigla }) => ({
        name: nome,
        value: sigla,
      }))
    },

    cities() {
      return this.locations.cities.map(({ nome }) => ({
        name: nome,
        value: nome.toUpperCase(),
      }))
    },
  },

  watch: {
    async local(value) {
      if (this.stateFocused && value) await this.getIBGEData('cities')
    },
  },

  beforeDestroy() {
    this.sourceAPI.cancel('Requisição ao IBGE cancelada')
  },

  async mounted() {
    this.$emit('loading', false)
    await this.getIBGEData('states')
  },

  methods: {
    backStep() {
      this.$emit('nextStep', -1)
    },

    getAddress() {
      const zipcode = this.zipcode.replace(/\D/g, '')

      if (zipcode.length !== 8) return

      this.$emit('loading', true)

      try {
        delete axios.defaults.headers.common['X-Authorization'] // prevent CORS errors

        axios
          .get(`https://viacep.com.br/ws/${zipcode}/json`)
          .then(async (response) => {
            if (response.data && response.data.logradouro) {
              this.local = response.data.uf

              await this.getIBGEData('cities')

              this.city = response.data.localidade.toUpperCase()
              this.neighborhood = response.data.bairro
              this.street = response.data.logradouro

              this.$refs.number.$el.querySelector('input').focus()
            }
          })
          .catch((err) => {
            const error = {
              message:
                err?.response?.data?.error?.message ||
                err?.error?.response?.data?.error?.message ||
                err?.message ||
                err,
              others: [],
            }

            if (err?.response?.data?.error?.errors) {
              const entries = Object.entries(err?.response?.data?.error?.errors)
              for (const [entry, values] of entries) {
                for (const [key, value] of Object.entries(values)) {
                  error.others.push({ entry, value })
                }
              }
            }
          })
          .finally(() => this.$emit('loading', false))
      } catch {
        // ...
      } finally {
        this.$emit('loading', false)
      }
    },

    async submit() {
      const formContractApi = new FormContractApi()

      this.actionLoading = true
      this.$emit('loading', true)

      const data = {
        account_id: this.account.id,
        zipcode: this.zipcode,
        street: this.street,
        number: this.number,
        unit: this.unit,
        neighborhood: this.neighborhood,
        city: this.city,
        state: this.local,
      }

      formContractApi
        .formContractAddress(data)
        .then(() => {
          this.$gtm.trackEvent({
            event: 'contract-address',
            account_id: this.account.id,
          })
          this.$emit('nextStep')
        })
        .catch((err) => {
          const error = {
            message: err.response.data.error.message,
            others: [],
          }

          if (err?.response?.data?.error?.errors) {
            const entries = Object.entries(err?.response?.data?.error?.errors)
            for (const [entry, values] of entries) {
              for (const [key, value] of Object.entries(values)) {
                error.others.push({ entry, value })
              }
            }
          }

          this.$emit('message', error)
        })
        .finally(() => {
          const el = document.querySelector('.form-contract__step-by-step')
          el.scrollIntoView()

          this.actionLoading = false
          this.$emit('loading', false)
        })
    },

    async getIBGEData(option) {
      const uri = {
        states: 'estados',
        cities: `estados/${this.local}/municipios`,
      }

      this.actionLoading = true

      if (option === 'states') {
        this.locations[option] = statesJSON
        this.actionLoading = false
      } else {
        delete axios.defaults.headers.common.Authorization // prevent CORS errors
        await axios
          .get(
            `https://servicodados.ibge.gov.br/api/v1/localidades/${uri[option]}?orderBy=nome`,
            {
              cancelToken: this.sourceAPI.token,
            }
          )
          .then(({ data }) => {
            this.locations[option] = data
          })
          .catch(() => {
            // ...
          })
          .finally(() => {
            this.actionLoading = false
          })
      }
    },

    handleAdd(nome) {
      if (!nome) return

      this.locations.cities.push({ nome })
      this.city = nome.toUpperCase()
    },
  },
}
</script>

<style lang="scss">
.form-address {
  .column {
    padding-bottom: 0;
    padding-top: 0;

    @media (min-width: 769px) {
      padding: auto;
    }
  }
}

.address {
  max-width: 80%;
  margin: 0 auto;

  .control {
    margin-top: 3.25rem;
  }

  .address-number {
    margin-top: 3.5rem;

    label,
    select {
      top: -1.5rem !important;
    }
  }

  &--span {
    color: $color-primary;
  }

  &__radio {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
    display: flex;
    flex-direction: column;

    p,
    label {
      font-size: 0.8rem;
      color: #616161;
      top: -1rem;
      left: 0;
      margin-bottom: 6px;
    }

    &--other {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      input {
        margin: 0;
        margin-right: 5px;
        margin-top: 4px;
      }

      .control {
        margin: -5px;
        width: 100%;
        padding-left: 10px;

        input {
          width: 100%;
        }
      }
    }
  }

  &__address-local {
    margin-top: 3.25rem;
    select {
      height: 33px;
    }
  }
}
</style>
