<template>
  <div id="app">
    <router-view />
    <loader
      v-if="loading"
      :object="color.light"
      :color1="color.secondary"
      :color2="color.primary"
      :opacity="opacity"
      :bg="backgroundColor"
      size="5"
      speed="2"
      name="circular"
      objectbg="#999793"
    />
  </div>
</template>

<script>
import _ from 'lodash'

import config from '@/lib/data/config'
import themeFallback from '@/lib/data/theme.json'

import themeMixin from '@/mixins/themeMixin'
import loadingMixin from '@/mixins/loadingViewMixin'

const fallbackColorLoading = {
  primary: '#d3d3d3',
  secondary: '#e5e5e5',
  light: '#f8f8f8',
}

export default {
  name: 'App',
  mixins: [themeMixin, loadingMixin],
  computed: {
    hasTheme() {
      return !!this.theme.business
    },
    color() {
      return this.theme?.color || fallbackColorLoading
    },
    opacity() {
      return this.hasTheme ? 80 : 100
    },
    backgroundColor() {
      return this.hasTheme ? '#343a40' : '#fff'
    },
    businessName() {
      return this.theme?.business?.name || themeFallback.name
    },
    pageID() {
      const domainParts = window.location.host.split('.')
      return domainParts[0]
    },
    isContract() {
      return ~this.$router.currentRoute.path.indexOf('-agora')
    },
    isValidation() {
      return ~this.$router.currentRoute.path.indexOf('validacao')
    },
  },
  async mounted() {
    this.loading = true

    await this.loadTheme()

    this.loading = false
  },
  methods: {
    joinchat() {
      this.$gtm.trackEvent({ event: 'whatsapp' })
      let strMessage = `Olá *${this.businessName}*! Eu gostaria de ter mais informações sobre FGTS (LP)`

      if (this.$route.query.utm_campaign) {
        var utm_campaign = this.$route.query.utm_campaign
        var arrParts = utm_campaign.split('-')
        var strInfluencer = arrParts.slice(-1).pop()

        strInfluencer =
          strInfluencer.charAt(0).toUpperCase() + strInfluencer.slice(1)
        strMessage = `Olá *${this.businessName}*! ${strInfluencer} falou de vocês, quero saber mais!`
      }
      window.location.href =
        'http://api.whatsapp.com/send?phone=+552139422489&text=' + strMessage
    },
    appendOctaDeskChat() {
      const script = document.createElement('script')
      script.innerHTML = (function (o, c, t, a, d, e, s, k) {
        o.octadesk = o.octadesk || {}
        s = c.getElementsByTagName('body')[0]
        k = c.createElement('script')
        k.async = 1
        k.src = t + '/' + a + '?showButton=' + d + '&openOnMessage=' + e
        s.appendChild(k)
      })(
        window,
        document,
        'https://chat.octadesk.services/api/widget',
        'pratadigital',
        true,
        true
      )
      document.body.appendChild(script)
    },
    async loadTheme() {
      const version = sessionStorage.getItem(this.themeVersionKey)
      let theme = sessionStorage.getItem(this.themeKey)

      // check version
      const isSameVersion = version && _.isEqual(version, config.THEME_VERSION)

      // if already loaded in $store
      if (isSameVersion && this.theme.business) return

      // get valid theme
      theme =
        !isSameVersion || !theme ? await this.fetchTheme() : JSON.parse(theme)

      // set theme
      await this.setTheme({ theme })
      this.registerCSSVariables()

      // to save the formatted theme (this.theme)
      sessionStorage.setItem(this.themeVersionKey, config.THEME_VERSION)
      sessionStorage.setItem(this.themeKey, JSON.stringify(this.theme))
    },
  },
}
</script>

<style>
@import 'https://www.pratadigital.com.br/wp-content/plugins/creame-whatsapp-me/public/css/joinchat.min.css?ver=4.1.14';

html {
  scroll-behavior: smooth;
}

body {
  overflow: hidden;
}

section {
  padding-top: 30px;
  padding-bottom: 30px;
}
</style>
