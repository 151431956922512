<template>
  <div class="menu">
    <div class="container">
      <nav
        class="navbar fgts-navbar"
        role="navigation"
        aria-label="main navigation"
      >
        <div class="navbar-brand fgts-navbar__navbar-brand">
          <a class="fgts-navbar__navbar-brand--navbar-item" :href="website">
            <img :src="imgLogo" :alt="businessName" width="147" />
          </a>

          <a
            v-if="showMenuLinks"
            role="button"
            class="navbar-burger fgts-navbar__navbar-burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
            :class="{ 'is-active': showNav }"
            @click="showNav = !showNav"
          >
            <img :src="iconMenu" width="45" height="45" />
          </a>
        </div>

        <div
          v-if="showMenuLinks"
          id="navbarBasicExample"
          class="navbar-menu fgts__navbar-menu"
          :class="{ 'is-active': showNav }"
        >
          <div
            class="btn-close"
            :class="{ 'is-active': showNav }"
            @click="showNav = !showNav"
          >
            <img :src="closeButton" />
          </div>

          <div v-if="isFGTS" class="fgts__navbar-start">
            <a class="navbar-item is-active" @click="contrateAgora">
              <img :src="icon" /> <b>CONTRATE AGORA</b>
            </a>
            <a class="navbar-item" @click="entenda">Entenda</a>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'BaseMenu',
  props: {
    contractNow: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showNav: false,
    iconMenu: require('@/assets/img/icon-menu.png'),
    icon: require('@/assets/img/fgts-lp/icon-menu.png'),
    closeButton: require('@/assets/img/close.png'),
  }),
  computed: {
    ...mapState({
      theme: (state) => state.theme.theme,
    }),
    pageID() {
      const domainParts = window.location.host.split('.')
      return domainParts[0]
    },
    isFGTS() {
      return this.pageID.indexOf('fgts') !== -1
    },
    imgLogo() {
      return this.theme.logo?.desktop ?? ''
    },
    businessName() {
      return this.theme.business?.name ?? ''
    },
    website() {
      return this.theme.business?.website ?? ''
    },
    showMenuLinks() {
      return this.theme?.edit?.show?.base_menu?.menu_links ?? false
    },
  },
  methods: {
    goTo(refName) {
      this.showNav = !this.showNav
      var result = document.getElementById(refName)
      result.scrollIntoView()
    },
    entenda() {
      if (document.getElementById('faq')) {
        this.goTo('faq')
      } else {
        this.$gtm.trackEvent({ event: 'entenda' })
        let strUrl = 'entenda?'

        if (this.$route.query.utm_source) {
          strUrl += 'utm_source=' + this.$route.query.utm_source + '&'
        }
        if (this.$route.query.utm_medium) {
          strUrl += 'utm_medium=' + this.$route.query.utm_medium + '&'
        }
        if (this.$route.query.utm_campaign) {
          strUrl += 'utm_campaign=' + this.$route.query.utm_campaign + '&'
        }

        window.location.href = strUrl
      }
    },
    contrateAgora() {
      if (this.contractNow) {
        this.$gtm.trackEvent({ event: 'contract' })
        let strUrl = ''

        switch (this.$router.currentRoute.path) {
          case '/supersim':
            strUrl = '/supersim-contrate-agora?'
            break
          case '/e-contrate':
            strUrl = '/e-contrate-agora?'
            break
          case '/c-contrate':
            strUrl = '/c-contrate-agora?'
            break
          default:
            strUrl = '/contrate-agora?'
            break
        }

        if (this.$route.query.utm_source) {
          strUrl += 'utm_source=' + this.$route.query.utm_source + '&'
        }
        if (this.$route.query.utm_medium) {
          strUrl += 'utm_medium=' + this.$route.query.utm_medium + '&'
        }
        if (this.$route.query.utm_campaign) {
          strUrl += 'utm_campaign=' + this.$route.query.utm_campaign + '&'
        }

        this.$router.push(strUrl)
      } else {
        this.$gtm.trackEvent({ event: 'whatsapp' })
        let strMessage = `Olá *${this.businessName}*! Eu gostaria de ter mais informações sobre FGTS (LP)`

        if (this.$route.query.utm_campaign) {
          var utm_campaign = this.$route.query.utm_campaign
          var arrParts = utm_campaign.split('-')
          var strInfluencer = arrParts.slice(-1).pop()

          strInfluencer =
            strInfluencer.charAt(0).toUpperCase() + strInfluencer.slice(1)
          strMessage = `Olá *${this.businessName}*! ${strInfluencer} falou de vocês, quero saber mais!`
        }
        window.location.href =
          'http://api.whatsapp.com/send?phone=+552139422489&text=' + strMessage
      }
    },
  },
}
</script>

<style lang="scss">
.menu {
  box-shadow: 0px -2px 15px 14px rgba(134, 134, 134, 0.3);
}

.fgts-navbar {
  &__navbar-brand {
    height: 80px;
    padding: 0 20px;
    justify-content: space-between;
    align-items: center !important;

    &--navbar-item {
      height: 44px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  &__navbar-burger {
    background-color: transparent !important;
    outline: none !important;
    height: 45px !important;
    width: 45px !important;
    margin-left: 0 !important;
  }
}

.btn-close {
  text-align: right;
  padding: 20px 25px;
  display: flex !important;
  justify-content: flex-end;

  @media (min-width: 769px) {
    display: none !important;
  }
}
.fgts {
  &__navbar-menu {
    height: calc(100vh - 80px);
    position: absolute;
    width: 100%;
    top: 0;
    height: 100vh;
    background-image: url('~@/assets/img/bg-menu-mobile.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 1s ease-in-out;

    @media (min-width: 769px) {
      background-image: none;
      position: unset;
      height: 80px;
      display: flex;
      background-image: none !important;
      justify-content: flex-end;
      align-items: center;
      flex-shrink: unset !important;
    }
  }

  &__navbar-start {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -100px;

    @media (min-width: 769px) {
      margin-top: auto;
      flex-direction: row;
      margin-top: 0;
      display: flex;
      margin-top: 0;
    }

    a {
      width: 100%;
      text-align: center;
      line-height: 2;
      color: #ffffff;
      font-size: 29px;
      font-family: 'Montserrat-Regular';

      @media (min-width: 769px) {
        color: $color-secondary;
        font-size: 17px;
        font-family: Montserrat-Regular;
        height: auto;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: fit-content;
      }

      &:hover {
        background-color: $color-secondary-950 !important;
        color: #ffffff;

        @media (min-width: 769px) {
          background-color: unset !important;
          color: $color-secondary-950 !important;
        }
      }

      img {
        margin-right: 5px;
      }

      span {
        width: 30px;
      }
    }

    .is-active {
      font-family: Montserrat-Bold;
      width: 100%;
      background-image: url('~@/assets/img/bg-menu-mobile.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      color: #ffffff !important;

      @media (min-width: 769px) {
        width: fit-content;
        background-image: none;
        color: $color-secondary-950 !important;
      }
    }
  }
}
</style>
