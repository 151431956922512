import Vue from 'vue'
import money from 'v-money'
import loader from 'vue-ui-preloader'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/browser'

import App from './App.vue'
import router from './router'
import store from './store'
import meta from 'vue-meta'
import { ValidationProvider, ValidationObserver } from './utils/vee-validate'
import { truncate } from './utils/filters'

import '../plugins'

require('@/assets/scss/main.scss')
require('@/assets/scss/material-form.scss')

Vue.config.productionTip = false
// Vue.config.devtools = true

Vue.use(meta)
Vue.use(loader)
Vue.use(money, { precision: 2, decimal: ',', thousands: '.', prefix: 'R$ ' })

Vue.component('VValidate', ValidationProvider)
Vue.component('VValidateObserver', ValidationObserver)

Vue.filter('truncate', truncate)

if (process.env.NODE_ENV === 'production')
  Sentry.init({
    Vue,
    dsn: 'https://ada29657a6084892ba84fdf07d53a154@o1313975.ingest.sentry.io/6564842',
    integrations: [
      new BrowserTracing({
        tracingOrigins: ['localhost', /^\//],
      }),
    ],
    tracesSampleRate: 0.1,
  })

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
