import Vue from 'vue'
import VueRouter from 'vue-router'

// modules
import HomeModule from './modules/home'
import FormModule from './modules/form'

// views
import FormView from '@/views/FormView.vue'
import WhatsView from '@/views/WhatsView.vue'
import SimuleView from '@/views/SimuleView.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...HomeModule,
    ...FormModule,
    {
      path: '/entenda',
      name: 'fgts-lp-info-whatsapp',
      component: WhatsView,
    },
    {
      path: '/simule',
      name: 'fgts-lp',
      component: SimuleView,
    },
    {
      path: '*', // fallback route
      name: 'form',
      component: FormView,
    },
  ],
})

export default router
