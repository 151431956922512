<template>
  <div class="modal is-active">
    <div class="modal-background" @click="close" />

    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ title }}</p>
        <button class="delete" aria-label="close" @click="close" />
      </header>

      <section class="modal-card-body">
        <slot name="body" />

        <div
          v-if="typeof messageError === 'object' && messageError.message"
          key="message-error-object"
          class="alert"
        >
          <small class="has-text-danger">
            Alerta: {{ messageError.message }}
          </small>
          <ul v-if="messageError.others && messageError.others[0]">
            <li v-for="(message, index) in messageError.others" :key="index">
              <small class="has-text-danger">{{ message.value }} </small>
            </li>
          </ul>
        </div>

        <div
          v-else-if="isErrorInstance(messageError)"
          key="message-error-Error"
          class="alert"
        >
          <p class="has-text-danger">Alerta: {{ messageError }}</p>
        </div>

        <div
          v-else-if="typeof messageError === 'string' && messageError"
          key="message-error-string"
          class="alert"
        >
          <p class="has-text-danger">Alerta: {{ messageError }}</p>
        </div>
      </section>

      <footer class="modal-card-foot">
        <template v-if="!messageError">
          <button
            v-if="hasBtnSecondary"
            class="button"
            @click="click('secondary')"
          >
            {{ btnSecondary }}
          </button>
          <button
            v-if="hasBtnPrimary"
            class="button is-primary ml-auto"
            @click="click('primary')"
          >
            {{ btnPrimary }}
          </button>
        </template>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseModal',

  props: {
    title: {
      type: String,
      default: '# Title',
      required: false,
    },

    hasBtnSecondary: {
      type: Boolean,
      default: true,
      required: false,
    },

    hasBtnPrimary: {
      type: Boolean,
      default: true,
      required: false,
    },

    btnSecondary: {
      type: String,
      default: 'Close',
      required: false,
    },

    btnPrimary: {
      type: String,
      default: 'Save changes',
      required: false,
    },

    messageError: {
      type: [String, Error, Object],
      default: '',
      required: false,
    },
  },

  created() {
    window.addEventListener('keydown', this.escape)
  },

  destroyed() {
    window.removeEventListener('keydown', this.escape)
  },

  methods: {
    escape(event) {
      if (event.key === 'Escape') this.close()
    },

    close() {
      this.$emit('close')
    },

    click(index) {
      this.$emit(`click-${index}`)
    },

    isErrorInstance(data) {
      return data instanceof Error
    },
  },
}
</script>

<style lang="scss" scoped>
.is-primary {
  background-color: $color-primary !important;
  color: $color-secondary !important;
}

.ml-auto {
  margin-left: auto;
}

.button {
  display: block;
  height: auto;
  padding: 10px 20px;

  text-transform: uppercase;
}

.alert {
  padding: 20px;
}
</style>
