<template>
  <div
    :class="{
      'has-error': hasError,
      'has-success': isPassed,
    }"
    class="control has-icons has-icons-right"
  >
    <input
      v-if="format && !blur"
      v-mask="format"
      :value="value"
      :class="{ 'has-value': value }"
      :type="type"
      :placeholder="placeholder"
      :maxlength="maxlength"
      v-bind="$attrs"
      @input="$emit('input', $event.target.value)"
    />
    <input
      v-if="format && blur"
      v-mask="format"
      :value="value"
      :class="{ 'has-value': value }"
      :type="type"
      :placeholder="placeholder"
      :maxlength="maxlength"
      v-bind="$attrs"
      @blur="blur"
      @input="$emit('input', $event.target.value)"
    />
    <input
      v-if="!format && blur"
      :value="value"
      :class="{ 'has-value': value }"
      :type="type"
      :placeholder="placeholder"
      :maxlength="maxlength"
      v-bind="$attrs"
      @blur="blur"
      @input="$emit('input', $event.target.value)"
    />
    <input
      v-if="!format && !blur"
      :value="value"
      :class="{ 'has-value': value }"
      :type="type"
      :placeholder="placeholder"
      :maxlength="maxlength"
      v-bind="$attrs"
      @input="$emit('input', $event.target.value)"
    />
    <span
      v-if="hasIcon"
      :class="{
        'has-text-errorlight': hasError,
        'has-text-successlight': isPassed,
      }"
      class="icon is-right"
    >
    </span>
    <label class="label">
      <slot></slot>
    </label>
    <i v-if="hasBar" class="bar"></i>
    <p v-if="hasError" class="help is-error">
      {{ firstError }}
    </p>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'

export default {
  name: 'BaseInput',

  directives: { mask },

  props: {
    value: {
      required: true,
    },
    type: {
      default: 'text',
    },
    hasError: {
      type: Boolean,
    },
    hasIcon: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    firstError: {
      type: String,
    },
    isPassed: {
      type: Boolean,
    },
    format: {
      default: '',
    },
    maxlength: {
      default: '',
    },
    blur: {
      default: '',
    },
    hasBar: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
