<template>
  <div class="form-info-basic">
    <div class="columns is-multiline">
      <div class="column is-full">
        <div class="form-contract__title">
          <h2><span style="font-size: 50px">Dados Básicos</span></h2>
        </div>
      </div>

      <div class="column">
        <div class="info-basic">
          <base-input
            :value="name"
            :has-error="
              validation.hasError('name') || name_error ? true : false
            "
            :first-error="validation.firstError('name') || name_error"
            :is-passed="validation.isPassed('name') && !name_error"
            @input="name = $event"
          >
            Nome <span class="info-basic--span">*</span>
          </base-input>

          <base-input :value="email" type="email" @input="email = $event">
            Email <span class="info-basic--span">*</span>
          </base-input>

          <base-input
            :value="phone"
            :has-error="
              validation.hasError('phone') || phone_error ? true : false
            "
            :first-error="validation.firstError('phone') || phone_error"
            :is-passed="validation.isPassed('phone') && !phone_error"
            :type="'tel'"
            :format="'(##) #####-####'"
            @input="phone = $event"
          >
            Celular com DDD <span class="info-basic--span">*</span>
          </base-input>

          <base-input
            :value="document"
            :has-error="
              validation.hasError('document') || document_error ? true : false
            "
            :first-error="validation.firstError('document') || document_error"
            :is-passed="validation.isPassed('document') && !document_error"
            type="tel"
            format="###.###.###-##"
            @input="document = $event"
          >
            CPF <span class="document--span">*</span>
          </base-input>
        </div>
      </div>

      <div class="column">
        <div class="info-basic">
          <div
            v-if="
              (!$route.query.utm_campaign && !utm_campaign) ||
              $route.query.utm_campaign == 'octa'
            "
            :class="{
              'has-text-errorlight':
                validation.hasError('referral') || referral_error
                  ? true
                  : false,
              'has-text-successlight':
                validation.isPassed('referral') && !referral_error,
            }"
            class="control address__address-local address-number"
          >
            <select v-model="referral">
              <option :value="null">ESCOLHER</option>
              <option
                v-for="(item, index) in referrals"
                :key="index"
                :value="item.referral"
                :required="true"
              >
                {{ item.label }}
              </option>
            </select>
            <label class="label">Como você nos conheceu? <span>*</span></label>
            <i class="bar" />
            <p
              v-if="
                validation.hasError('referral') || referral_error ? true : false
              "
              class="help is-error"
            >
              {{ validation.firstError('referral') || referral_error }}
            </p>
          </div>

          <div class="info-basic__gender">
            <p>Qual seu gênero/sexo? <span class="info-basic--span">*</span></p>
            <label v-for="(option, index) in genderOptions" :key="index">
              <input v-model="gender" type="radio" :value="option.value" />
              {{ option.label }}
            </label>
          </div>

          <base-input
            :value="birthdate"
            :has-error="
              validation.hasError('birthdate') || birthdate_error ? true : false
            "
            :first-error="validation.firstError('birthdate') || birthdate_error"
            :is-passed="validation.isPassed('birthdate') && !birthdate_error"
            :type="'tel'"
            :format="'##/##/####'"
            @input="birthdate = $event"
          >
            Você nasceu que dia?
            <span class="info-basic--span">*</span>
          </base-input>
          <small style="font-size: 0.8rem">(Dia/Mês/Ano)</small>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column is-6">
        <div class="info-basic">
          <div class="form-contract__button-next">
            <button
              :disabled="!formIsValidated || actionLoading"
              type="submit"
              @click="handleConsult"
            >
              PRÓXIMO
            </button>
          </div>
        </div>
      </div>
    </div>

    <base-modal
      v-if="modal.show"
      title="Autorizar consulta do FGTS"
      has-btn-primary
      has-btn-secondary
      btn-primary="Ok, eu autorizo"
      btn-secondary="Cancelar"
      @close="modal.show = false"
      @click-primary="submit"
      @click-secondary="modal.show = false"
    >
      <template #body>
        <p>
          <span>
            Para que seja possível realizar a antecipação do seu FGTS, é
            necessário que você antes tenha aceitado a opção de
            Saque-Aniversário no aplicativo do FGTS. Bem como, autorizar os
            bancos Money Plus e QI Tech para consultar o seu saldo do FGTS.
          </span>
        </p>
      </template>
    </base-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import SimpleVueValidator from 'simple-vue-validator'

// components
import BaseInput from '@/components/BaseInput.vue'
import BaseModal from '@/components/BaseModal.vue'

// data
import iReferrals from '@/lib/data/i-referrals.js'
import eReferrals from '@/lib/data/e-referrals.js'
import cReferrals from '@/lib/data/c-referrals.js'

// libs
import FormContractApi from '@/lib/api/FGTS'

const Validator = SimpleVueValidator.Validator.create({
  templates: {
    required: 'Este campo não pode ficar vazio',
  },
})

export default {
  name: 'InfoBasic',
  components: {
    BaseInput,
    BaseModal,
  },
  mixins: [SimpleVueValidator.mixin],
  props: {
    isPage: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  validators: {
    name(value) {
      this.name_error = ''
      return Validator.value(value)
        .required()
        .regex(/^[^0-9]*$/, 'Não é permitido números')
        .regex(
          /[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]+\s+[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]+/g,
          'Escreva o nome completo'
        )
    },
    phone(value) {
      this.phone_error = ''
      return Validator.value(value).required()
    },
    document(value) {
      this.document_error = ''
      return Validator.value(value)
        .required()
        .minLength(14, 'Não foi possível validar seu CPF')
        .maxLength(14, 'Não foi possível validar seu CPF')
    },
    referral(value) {
      this.referral_error = ''
      return Validator.value(value).required()
    },
    birthdate(value) {
      this.birthdate_error = ''
      return Validator.value(value)
        .required()
        .regex(
          /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
          'Insira uma data válida'
        )
    },
    gender(value) {
      this.gender_error = ''
      return Validator.value(value).required()
    },
  },
  data: () => ({
    name_error: '',
    email_error: '',
    phone_error: '',
    document_error: '',
    utm_campaign_error: '',
    utm_source_error: '',
    utm_medium_error: '',
    seller_error: '',
    referral_error: '',
    gender_error: '',
    birthdate_error: '',
    name: '',
    email: '',
    phone: '',
    document: '',
    gender: '',
    birthdate: '',
    genderOptions: [
      { value: 'Feminino', label: 'Feminino/Mulher' },
      { value: 'Masculino', label: 'Masculino/Homem' },
    ],
    isOtherGender: false,
    referrals: null,
    actionLoading: false,
    form: {
      $_referral: '',
    },
    modal: {
      show: false,
      title: '',
      alert: '',
      error: '',
      images: {
        banner: require('@/assets/img/fgts-lp/banner-mobile.png'),
        dot: require('@/assets/img/fgts-lp/ponto.png'),
      },
    },
    promoter_id: null,
    promoter_token: null,
  }),
  computed: {
    ...mapState({
      $_account: (state) => state.fgts.account,
      $_spreadsheet: (state) => state.fgts.spreadsheet,
    }),
    account: {
      get() {
        return this.$_account
      },
      set(payload) {
        this.setAccount(payload)
      },
    },
    spreadsheet: {
      get() {
        return this.$_spreadsheet
      },
      set(payload) {
        this.setSpreadsheet(payload)
      },
    },
    referral: {
      get() {
        const utm_campaign = this.$route.query.utm_campaign
        const referral = this.form.$_referral

        let status = 'pessoa-outros'

        if (referral) return referral

        if (utm_campaign) return utm_campaign

        if (this.isPage.fgts) {
          status = 'fgts-outros'
        }
        if (this.isPage.vehicle) {
          status = 'veiculo-outros'
        }
        if (this.isPage.immobile) {
          status = 'imovel-outros'
        }

        return status
      },
      set(value) {
        this.$set(this.form, '$_referral', value)
      },
    },
    seller() {
      return this.$route.query.seller
    },
    utm_campaign() {
      return this.$route.query.utm_campaign
    },
    utm_source() {
      return this.$route.query.utm_source
    },
    utm_medium() {
      return this.$route.query.utm_medium
    },
    formIsValidated() {
      const validate = (target) => this.validation.isPassed(target)
      return (
        validate('name') &&
        validate('phone') &&
        validate('document') &&
        validate('birthdate') &&
        validate('gender')
      )
    },
    isValidDocument() {
      return this.validation.isPassed('document')
    },
    hasPassed() {
      return this.validation.isPassed('document')
    },
    hasError() {
      return this.validation.hasError('document')
    },
    hasFirstError() {
      return this.validation.firstError('document')
    },
    stepsContainer() {
      const el = document.querySelector('.form-contract__step-by-step')
      return el
    },
  },
  created() {
    switch (this.$router.currentRoute.path) {
      case '/supersim-contrate-agora':
        this.utm_campaign = 'fgts-supersim'
        this.referral = 'fgts-supersim'
        this.referrals = []
        break
      case '/e-contrate-agora':
        this.referrals = eReferrals
        break
      case '/c-contrate-agora':
        this.referrals = cReferrals
        break
      default:
        this.referrals = iReferrals
        break
    }
  },
  mounted() {
    this.savePromoterIDFromQueryString()
  },
  watch: {
    'modal.show': {
      handler(status) {
        if (!status) {
          this.modal.error = ''
          this.modal.alert = ''
        }
      },
    },
  },
  methods: {
    ...mapActions({
      setAccount: 'fgts/SET_ACCOUNT',
      setSpreadsheet: 'fgts/SET_SPREADSHEET',
      setStatus: 'balance/SET_STATUS',
      setDocument: 'balance/SET_DOCUMENT',
    }),

    // handlers
    savePromoterIDFromQueryString() {
      const urlParams = new URLSearchParams(window.location.search)

      this.promoter_id = urlParams.get('promoter_id')
      this.promoter_token = urlParams.get('p')

      this.account = {
        ...this.account,
        promoter_id: this.promoter_id,
        promoter_token: promoter_token,
        email: this.email,
      }
    },
    handleConsult() {
      let status = 'fgts'

      if (this.isPage.fgts) {
        this.modal.show = true
        return
      }

      if (this.isPage.vehicle) status = 'veículo'

      this.spreadsheet = status
      this.submit()
    },
    async getFormattedData() {
      const obs = this.spreadsheet || this.status

      return {
        utm_campaign: this.utm_campaign,
        utm_source: this.utm_source,
        utm_medium: this.utm_medium,
        referral: this.referral,
        seller: this.seller,
        name: this.name,
        email: this.email,
        phone: this.phone,
        document: this.document,
        birthdate: this.birthdate,
        gender: this.gender,
        tenant_id: 1,
        master_id: 1,
        promoter_id: this.promoter_id ?? this.account.promoter_id,
        token: this.promoter_token,
        ...(obs ? { obs } : ''),
      }
    },
    handleError({ data }) {
      const error = {
        message: data?.error?.message || data,
        others: [],
      }

      if (data?.error?.errors) {
        const entries = Object.entries(data?.error?.errors)
        for (const [entry, values] of entries) {
          for (const [key, value] of Object.entries(values)) {
            error.others.push({ entry, value })
          }
        }
      }

      this.$emit('message', error)
    },

    // api
    async submit() {
      const api = new FormContractApi()
      const el = this.stepsContainer

      const params = await this.getFormattedData()

      this.actionLoading = true
      this.modal.show = false
      this.$emit('loading', true)

      await api
        .formContractInfoBasic(params)
        .then(({ data }) => {
          this.emitGAEvent(data)

          this.account = { ...this.account, ...data.data }

          this.$emit('nextStep')
        })
        .catch((err) => this.handleError(err.response))
        .finally(() => {
          el.scrollIntoView()

          this.actionLoading = false
          this.$emit('loading', false)
        })
    },

    // navigation
    emitGAEvent({ data }) {
      if (process.env.NODE_ENV !== 'development') {
        this.$gtm.trackEvent({
          event: 'contract-info-basic',
          name: this.name,
          email: this.email,
          phone: this.phone,
          document: this.document,
          birthdate: this.birthdate,
          gender: data.gender,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
* ::v-deep input,
select {
  &:disabled {
    background-color: #f7f7f7;
  }
}

.form-info-basic {
  .column {
    padding-bottom: 0;
    padding-top: 0;

    @media (min-width: 769px) {
      padding: auto;
    }
  }
}

.info-basic {
  max-width: 80%;
  margin: 0 auto;

  .control {
    margin-top: 3.25rem;
  }

  &--span {
    color: $color-primary;
  }

  &__gender {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
    display: flex;
    flex-direction: column;

    p,
    label {
      font-size: 0.8rem;
      color: #616161;
      top: -1rem;
      left: 0;
      margin-bottom: 6px;
    }
  }

  &__born-local {
    margin-top: 3.25rem;
    select {
      height: 33px;
    }
  }
}
</style>
